body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  text-align: center;
  margin-top: 1.5em;
}

th {
  text-align: center;
}

td {
  text-align: center;
  padding: 0;
}

table { 
  border-spacing: 0;
  border-collapse: separate;
}

table tr:nth-child(2n) {
  background-color: rgba(7,84,140,0.05);
}

.radio {
  display: inline;
}

input[type=radio],
input.radio {
  margin-right: 30px;
}

.container {
  max-width: 1200px;
  margin-bottom: 20px;
}


input[type=submit], button[type=submit] {
  margin-right: 10px;
}

button:hover {
  background-color: rgb(0, 55, 94) !important;
  border-color: rgb(0, 55, 94) !important;
  color: white !important;
}

.page {
  margin-top: 54px;
}

.red {
    background-color: red;
}

.red-text {
    color: red !important;
}

.yellow {
    background-color: yellow !important;
    color: black !important;
}

.green {
    background-color: green;
}

.array-item-list {
  flex-direction: column;
}

.array-item {
  margin: 10px;
  max-width: 175px;
}

.btn-info {
  width: 175px;
  margin-left: 10px;
}

.btn-add > i {
  display: none;
}

.btn-add::after {
  content: "+";
  font-weight: bold;
  font-size: 25px;
}

.array-item-remove > i {
  display: none;
}

.array-item-remove::after {
  content: "-";
  font-weight: bold;
  font-size: 25px;
}

.col_xs-9 {
  float: left;
}

.col_xs-3 {
  float: right;
}

.datafield {
  margin-bottom: 10px;
}

.leaflet-control-zoom  {
    display: none;
}

#map-area {
    position: relative;
    margin-top: -9px;
    width: 100%;
    height: 100%;
    z-index: 0
}

.state {
  display: inline-block;
  border: 1px solid black;
  background: rgba(7,84,140,0.3);
  padding: 4px;
  color: white;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.state.selected {
  background: rgba(7,84,140,0.9);
  cursor: auto;
}

.pswp__zoom-wrap {
  will-change: auto !important;
  -webkit-backface-visibility: visible !important;
}

.button-primary {
  margin-right: 15px;
}

.main.loader {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-width: 15px;
}

.rv-xy-plot {
  background-color: white;
}

/* Reptail styles */
#reptail {
  padding-top: 14px;
  color: white;
}

#reptail #logo{
  background-image: url("reptail-logo.png") !important;
}

#reptail #top-info {
  color: #222222 !important;
}

#reptail table tr:nth-child(2n) {
  background: rgb(255, 255, 255, 0.15);
}

#reptail input {
  background: white;
  color: black;
}

#reptail input[type="submit"] {
  background: #F9CB01 !important;
  color: #222222 !important;
}

#reptail select {
  color: white !important;
  border-color: white !important;
}

#reptail select.required {
  background-color: white !important;
  color: #222222 !important;
}

#reptail .app-button {
  background: #222222;
  border-color: white;
}

#reptail .app-button p, #reptail .list-table-header {
  color: white;
}

#reptail .app-button img, #reptail .app-button i {
  -webkit-filter: grayscale(100%) brightness(360%) invert(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) contrast(360%) invert(100%);
}

#reptail #header, #reptail #navigate-left, #reptail #menu-top,
#reptail button {
  background: #F9CB01 !important;
  color: #222222 !important;
}

#reptail #chat {
  background: rgb(249, 203, 1, 0.9);
  color: #222222 !important;
}

#reptail #header i, #reptail #chat-header, #reptail #message-area {
  color: #222222 !important;
}


#reptail #top-corner-container, #reptail #chat-button {
  background-color: #222222 !important;
  color: white !important;
}

#reptail #weather, #reptail #weather-overall span,
#reptail .confirm-area, #reptail .load-data-modal {
  background: #222222 !important;
  color: white !important;
}

#reptail #top-contract-and-site-select .selector select {
  border-color: #222222 !important;
  color: #222222 !important;
}

#reptail #menu-content, #reptail option {
  background: #F9CB01 !important;
  color: #222222 !important;
}

#reptail  #menu-content li {
  border-color: #222222 !important;
}

#reptail .close {
  background: #F9CB01 !important;
}

#reptail #message-send-button {
  background: #222222 !important;
  color: white !important;
}

#reptail #message-type-bar{
  color: #222222 !important;
}

#reptail .data-field-top, #reptail .construction-site-select, #reptail .sensor-select, #reptail .state {
  background: #F9CB01 !important;
  color: #222222 !important;
}

#reptail .button-view:disabled {
  background-color: rgb(249, 203, 1, 0.6) !important;
}

#reptail #combine-loader {
  color: #222222 !important;
}

#reptail .state.selected, #reptail .construction-site-select.selected, #reptail .sensor-select.selected {
  background-color: rgb(249, 203, 1, 0.6) !important;
  color: white !important;
}

#reptail .modal div {
  background: #222222 !important;
  color: white !important;
}

/* /// */